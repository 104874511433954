import React, {useEffect, useState} from 'react';
import './App.css';

function App() {
  const [blockHeight, setBlockHeight] = useState();
  useEffect(()=> {
    fetch("http://node1.neverodd.co.uk/data/info.json")
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      setBlockHeight(response.blocks)
    })
  }, [])
  
  return (
    <div className="App">
      <header className="App-header">
        <h2>Node Status</h2>
        <p>
          Current block height: {blockHeight}
        </p>
      </header>
    </div>
  );
}

export default App;
